import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { omit } from 'lodash'

import { withRouter } from '../../common/withRouter'
import ManageFilterForm from '../../components/tenants/manageFilter'
import { errToast } from '../../lib/toast'
import { tenants } from '../../actions'

const { updateTenantFilter, fetchTenantFilterById } = tenants

const EditFilter = ({ history, location }) => {
  const { id: tenantId, filterId } = useParams()
  const [filter, setFilter] = useState(null)

  useEffect(() => {
    fetchTenantFilterById(tenantId, filterId)
      .then(({ filter }) => setFilter(filter))
  }, [tenantId, filterId])

  const handleSubmit = (updatedFilter) => {
    return updateTenantFilter(tenantId, filterId, omit(updatedFilter, 'name'))
      .then(() => history(`/tenants/${tenantId}/filters`, { replace: false }))
      .catch(errToast)
  }

  if (!filter) return <div>Loading...</div>

  return (
    <ManageFilterForm
      tenantId={tenantId}
      tenantName={location.state?.tenantName}
      initialValues={filter} onSubmit={handleSubmit}
    />
  )
}

export default withRouter(EditFilter)
