import React, { Component } from 'react'
import { withRouter } from '../../common/withRouter'
import { Header, Icon } from 'semantic-ui-react'
import { Button } from '@waylay/react-components'

import TenantDropdown from '../tenantDropdown'
import DetailView from '../../components/companies/detail'
import TenantsTable from '../../components/tenants/list'
import Breadcrumb, { NO_TITLE } from '../../components/breadcrumb'
import authClient from '../../lib/client'

class CompaniesDetail extends Component {
  constructor (props) {
    super(props)
    this.state = { details: {} }
  }

  componentDidMount () {
    const { match } = this.props
    const { id } = match.params

    this.fetchCompany(id)
  }

  async fetchCompany (id) {
    const details = await authClient.company(id)
    this.setState({ details })
  }

  handleTenantChange (tenantId) {
    this.setState({ selectedTenant: tenantId })
  }

  async assignToCompany () {
    const { selectedTenant, details } = this.state

    await authClient.assignToCompany(selectedTenant, details.id)
    await this.fetchCompany(details.id)
    this.setState({ selectedTenant: null })
  }

  unAssignFromCompany (id) {
    const { details } = this.state
    authClient.unAssignFromCompany(id)
      .then(() => this.fetchCompany(details.id))
  }

  createRemoveModal (id) {
    return {
      header: 'Unassign tenant',
      action: 'Unassign',
      icon: 'trash',
      onConfirm: (id) => () => this.unAssignFromCompany(id)
    }
  }

  render () {
    const { details } = this.state
    const { tenants = [] } = details
    const modal = this.createRemoveModal()

    return (
      <div>
        <div id='top-bar'>
          <Breadcrumb titles={[NO_TITLE, details.name]} />
        </div>

        <Header>Details</Header>
        <DetailView company={details} />

        <Header>Tenants</Header>

        <TenantDropdown fluid selectedTenant={this.state.selectedTenant} onTenantSelected={tenantId => this.handleTenantChange(tenantId)} multiple={false} />
        <Button style={{ marginLeft: 10 }} onClick={() => this.assignToCompany()}>
          <Icon name='add' />Add tenant
        </Button>

        <TenantsTable tenants={tenants} modal={modal} />
      </div>
    )
  }
}

export default withRouter(CompaniesDetail)
