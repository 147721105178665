import client from '../lib/client'
import { tenants as types } from './types'

function fetchTenant (id) {
  return client.tenant(id)
    .then(tenant => ({
      type: types.FETCH_TENANT,
      tenant
    }))
}

function fetchTenants () {
  return client.tenants()
    .then(tenants => ({
      type: types.FETCH_TENANTS,
      tenants
    }))
}

function createTenant (tenant) {
  return client.createTenant(tenant)
    .then(tenant => ({
      type: types.CREATED_TENANT,
      tenant
    }))
}

function updateTenant (id, state) {
  return client.updateTenant(id, state)
    .then(() => ({
      type: types.UPDATED_TENANT,
      id
    }))
}

function removeTenant (id) {
  return client.removeTenant(id)
    .then(() => ({
      type: types.DELETED_TENANT,
      id
    }))
}

function addUserToTenant (userId, tenantId) {
  return client.addUserToTenant(userId, tenantId)
    .then(() => ({
      type: types.USER_ADDED_TO_TENANT,
      id: userId
    }))
}

function removeUserFromTenant (userId, tenantId) {
  return client.removeUserFromTenant(userId, tenantId)
    .then(() => ({
      type: types.USER_REMOVED_FROM_TENANT,
      id: userId
    }))
}

function createTenantSetting (tenantId, settings) {
  return client.createTenantSetting(tenantId, settings)
    .then(tenant => ({
      type: types.CREATED_TENANT_SETTING,
      tenant
    }))
}

function deleteTenantSetting (tenantId, key) {
  return client.deleteTenantSetting(tenantId, key)
    .then(() => ({
      type: types.DELETED_TENANT_SETTING,
      id: tenantId,
      key
    }))
}

function fetchTenantFilters (tenantId) {
  return client.getTenantFilters(tenantId)
    .then(filters => ({
      type: types.FETCH_TENANT_FILTERS,
      filters
    }))
}

function fetchTenantFilterById (tenantId, filterId) {
  return client.getTenantFilter(tenantId, filterId)
    .then(filter => ({
      type: types.FETCH_TENANT_FILTER,
      filter
    }))
}

function createTenantFilter (tenantId, filter) {
  return client.createTenantFilter(tenantId, filter)
    .then(filter => ({
      type: types.CREATED_TENANT_FILTER,
      filter
    }))
}

function updateTenantFilter (tenantId, filterId, filter) {
  return client.updateTenantFilter(tenantId, filterId, filter)
    .then(filter => ({
      type: types.UPDATED_TENANT_FILTER,
      filter
    }))
}

function deleteTenantFilter (tenantId, filterId) {
  return client.deleteTenantFilter(tenantId, filterId)
    .then(() => ({
      type: types.DELETED_TENANT_FILTER,
      tenantId,
      filterId
    }))
}

export default {
  fetchTenant,
  fetchTenants,

  createTenant,
  updateTenant,
  removeTenant,

  addUserToTenant,
  removeUserFromTenant,

  createTenantSetting,
  deleteTenantSetting,

  fetchTenantFilters,
  fetchTenantFilterById,
  createTenantFilter,
  updateTenantFilter,
  deleteTenantFilter
}
