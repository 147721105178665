import React, { Component } from 'react'
import { withRouter } from '../../common/withRouter'
import { get } from 'lodash'

import ManageFilterForm from '../../components/tenants/manageFilter'
import { errToast } from '../../lib/toast'
import { tenants } from '../../actions'

const { createTenantFilter } = tenants

class AddFilter extends Component {
  handleSubmit = (filter) => {
    const tenantId = get(this.props, ['match', 'params', 'id'])
    createTenantFilter(tenantId, filter)
      .then(() => this.props.history(`/tenants/${tenantId}/filters`, { replace: false }))
      .catch(errToast)
  }

  render () {
    const tenantId = get(this.props, ['match', 'params', 'id'])
    return (
      <ManageFilterForm
        tenantId={tenantId}
        tenantName={this.props.location.state?.tenantName}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

export default withRouter(AddFilter)
