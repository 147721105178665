import React from 'react'
import { useParams, Navigate } from 'react-router-dom'

const RedirectWithParams = ({ pathBuilder }) => {
  const params = useParams()
  const targetPath = pathBuilder(params)

  if (!targetPath) {
    console.error('RedirectWithParams: pathBuilder did not return a valid path.')
    return null
  }

  return <Navigate to={targetPath} replace />
}

export default RedirectWithParams
