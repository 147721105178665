import React from 'react'
import { Button, Icon, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ConfirmationModal from '../confirmationModal'
import CopyToClipboard from '../copyToClipboard'
import { Table } from '@waylay/react-components'
import TableHeaderCellBold from '../../common/tableHeaderCellBold'

const CELL_STYLE = {
  display: 'flex',
  alignItems: 'center'
}

const CELL_CONTENT_STYLE = {
  display: 'inline-block',
  maxWidth: '95%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginRight: 5
}

export default ({ tenant, onDeleteFilter, onToggleActive }) => {
  return (
    <div>
      <div id='top-bar'>
        <Header>Filters</Header>
        <Link
          className='ui button green'
          state={{ tenantName: tenant.name }}
          to={`/tenants/${tenant.id}/filters/new`}
        >
          <Icon name='add' />Add filter
        </Link>
      </div>
      <Table fixed style={{ tableLayout: 'fixed', width: '100%' }}>
        <Table.Header>
          <Table.Row>
            <TableHeaderCellBold style={{ width: '0.8rem' }} />
            <TableHeaderCellBold>Name</TableHeaderCellBold>
            <TableHeaderCellBold>Definition</TableHeaderCellBold>
            <TableHeaderCellBold style={{ width: '18.7rem' }}>Actions</TableHeaderCellBold>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(tenant.filters ?? []).map(filter => (
            <Filter key={filter.id} tenant={tenant} filter={filter} onDelete={onDeleteFilter} onToggleActive={onToggleActive} />
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

const Filter = ({ tenant, filter, onDelete, onToggleActive }) => {
  const { id, name, filter: definition, isActive } = filter
  return (
    <Table.Row>
      <Table.Cell textAlign='center'>
        {isActive && <Icon name='check circle' color='green' />}
      </Table.Cell>
      <Table.Cell>
        <div style={CELL_STYLE}>
          <div style={CELL_CONTENT_STYLE}>{name}</div>
          <CopyToClipboard target={<Icon color='grey' name='clipboard' />} value={name} />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div style={CELL_STYLE}>
          <div style={CELL_CONTENT_STYLE}>{definition}</div>
          <CopyToClipboard target={<Icon color='grey' name='clipboard' />} value={definition} />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button.Group
            size='tiny' style={{
              display: 'flex',
              gap: '0.5rem',
              maxWidth: '240px',
              justifyContent: 'space-between'
            }}
          >
            <Button
              basic
              color={isActive ? 'blue' : 'green'}
              onClick={() => onToggleActive(filter.id, !isActive)}
              style={{ flex: 1, maxWidth: '80px' }}
            >
              <Icon name={isActive ? 'toggle off' : 'toggle on'} />
              {isActive ? 'Disable' : 'Apply'}
            </Button>
            <Button
              as={Link}
              basic
              color='grey'
              state={{ tenantName: tenant.name }}
              to={`/tenants/${tenant.id}/filters/${id}/edit`}
              style={{ flex: 1 }}
            >
              <Icon name='pencil' />
              Edit
            </Button>
            <ConfirmationModal
              trigger={
                <Button basic color='red' style={{ flex: 1 }}>
                  <Icon name='trash' />
                  Delete
                </Button>
              }
              header='Delete Filter'
              subject={name}
              onConfirm={() => onDelete(id)}
            />
          </Button.Group>
        </div>
      </Table.Cell>
    </Table.Row>
  )
}
