import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Navigate, Route, Routes } from 'react-router'
import { Menu, Header, Search } from 'semantic-ui-react'
import { get, reject, differenceWith, isEqual } from 'lodash'
import { toast } from 'react-toastify'

import RoleDetail from '../../components/roles/detail'
import UsersDetail from '../../components/roles/users'
import PermissionsListManager from '../../components/roles/permissions'
import Breadcrumb, { NO_TITLE } from '../../components/breadcrumb'
import { withRouter } from '../../common/withRouter'
import { errToast } from '../../lib/toast'
import { roles, permissions } from '../../actions'

const {
  fetchRole,
  removePermissionFromRole,
  addPermissionToRole,
  fetchRoleUsers,
  updateRole
} = roles
const { fetchPermissions } = permissions

class Detail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      role: {},
      permissions: [],
      isLoading: false,
      search: '',
      users: [],
      serviceUsers: []
    }
  }

  componentDidMount () {
    this.setState({ isLoading: false, search: '' })

    const roleId = get(this.props, ['match', 'params', 'id'])
    fetchRole(roleId)
      .then(({ role }) => this.setState({ role }))
      .catch(errToast)

    fetchPermissions()
      .then(({ permissions }) => this.setState({ permissions }))
      .catch(errToast)

    fetchRoleUsers(roleId, true)
      .then(({ users }) => this.setState({ users }))
      .catch(errToast)

    fetchRoleUsers(roleId, false)
      .then(({ users }) => this.setState({ serviceUsers: users }))
      .catch(errToast)
  }

  addPermission (permissionId) {
    const roleId = get(this.state, ['role', 'id'])
    addPermissionToRole(roleId, permissionId)
      .then((res) => {
        const { role, permissions } = this.state

        const newPermission = permissions.find((p) => p.id === permissionId)
        const newRolePermissions = [...role.permissions, newPermission]
        const newRole = { ...role, permissions: newRolePermissions }

        this.setState({ role: newRole })
      })
      .catch(errToast)
  }

  removePermission (permissionId) {
    const roleId = get(this.state, ['role', 'id'])
    removePermissionFromRole(roleId, permissionId)
      .then(() => {
        const { role } = this.state

        const newRolePermissions = reject(role.permissions, {
          id: permissionId
        })
        const newRole = { ...role, permissions: newRolePermissions }

        this.setState({ role: newRole })
      })
      .catch(errToast)
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, search: value })

    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 500)
  }

  getPermissions () {
    const { role, search } = this.state

    const unassignedPermissions = this.getUnassignedPermissions()
    const { permissions: rolePermissions } = role

    if (search === '') { return { permissions: unassignedPermissions, rolePermissions } }

    const filteredUnassignedPermissions = unassignedPermissions.filter((p) =>
      p.name.startsWith(search)
    )
    const filteredRolePermissions = rolePermissions.filter((p) =>
      p.name.startsWith(search)
    )
    return {
      permissions: filteredUnassignedPermissions,
      rolePermissions: filteredRolePermissions
    }
  }

  getUnassignedPermissions () {
    const { role, permissions } = this.state
    return differenceWith(permissions, role.permissions, isEqual)
  }

  updateRole = (description) => {
    const roleId = get(this.state, ['role', 'id'])
    updateRole(roleId, { description })
      .then(() => {
        const { role } = this.state
        const newRole = { ...role, description }
        this.setState({ role: newRole })
      })
      .then(() => toast.warn('Role successfully updated'))
      .catch(errToast)
  }

  render () {
    const { role, search, isLoading, users, serviceUsers } = this.state
    const { permissions, rolePermissions } = this.getPermissions()
    if (!role) return null

    return (
      <div>
        <Menu secondary>
          <Breadcrumb titles={[NO_TITLE, role.name]} />
        </Menu>
        <div style={{ display: 'flex' }}>
          <div>
            <Menu pointing secondary vertical fluid>
              <NavLink className='item' to={`/roles/${role.id}/details`}>
                Details
              </NavLink>
              <NavLink className='item' to={`/roles/${role.id}/permissions`}>
                Permissions
              </NavLink>
              <NavLink className='item' to={`/roles/${role.id}/users`}>
                Users
              </NavLink>
              <NavLink className='item' to={`/roles/${role.id}/serviceUsers`}>
                Service Users
              </NavLink>
            </Menu>
          </div>
          <div style={{ flex: 1, marginLeft: 15 }}>
            <div>
              <Routes>
                <Route
                  path='/details'
                  element={
                    <span>
                      <div id='top-bar'>
                        <Header>Details</Header>
                      </div>
                      <RoleDetail
                        role={role}
                        handleDescriptionChange={this.updateRole}
                      />
                    </span>
                  }
                />
                <Route
                  path='/permissions'
                  element={
                    <div>
                      <div id='top-bar'>
                        <Header>Permissions</Header>
                        <Search
                          loading={isLoading}
                          onSearchChange={this.handleSearchChange.bind(this)}
                          value={search}
                          showNoResults={false}
                        />
                      </div>
                      <div>
                        <PermissionsListManager
                          rolePermissions={rolePermissions}
                          allPermissions={permissions}
                          addRolePermission={this.addPermission.bind(this)}
                          removeRolePermission={this.removePermission.bind(
                            this
                          )}
                        />
                      </div>
                    </div>
                  }
                />
                <Route
                  path='/users'
                  element={
                    <span>
                      <div id='top-bar'>
                        <Header>Users</Header>
                      </div>
                      <UsersDetail users={users} />
                    </span>
                  }
                />
                <Route
                  path='/serviceUsers'
                  element={
                    <span>
                      <div id='top-bar'>
                        <Header>Service Users</Header>
                      </div>
                      <UsersDetail users={serviceUsers} />
                    </span>
                  }
                />
                <Route
                  path='/'
                  element={<Navigate to={`/roles/${role.id}/details`} />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Detail)
