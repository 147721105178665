import React, { useEffect, useState } from 'react'
import { Tab } from 'semantic-ui-react'
import { withRouter } from '../../common/withRouter'
import { get } from 'lodash'
import Breadcrumb, { NO_TITLE } from '../../components/breadcrumb'
import UserInfo from '../../containers/users/info'
import UserTenants from '../../containers/users/tenants'
import UserKeys from '../../containers/users/keys'
import UserPermissions from '../../containers/users/permissions'
import UserRoles from '../../containers/users/roles'

const paneStyles = { padding: 0, border: 'none' }

const Detail = ({ id, user, tab, updateUser, history, location }) => {
  const [permissions, setPermissions] = useState([])
  const isServiceUser = location.pathname.includes('serviceUsers')
  const currentLocation = isServiceUser ? 'serviceUsers' : 'users'

  useEffect(() => {
    setPermissions(user?.permissions ?? [])
  }, [user])

  function onRoleChange (permissions) {
    setPermissions(permissions)
  }

  const panes = [
    {
      menuItem: {
        key: 'info',
        content: 'Info',
        onClick: () => history(`/${currentLocation}/${id}/info`, { replace: true })
      },
      pane: (
        <Tab.Pane key='info' style={paneStyles}>
          <UserInfo user={id} />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'tenants',
        content: 'Tenants',
        onClick: () => history(`/${currentLocation}/${id}/tenants`, { replace: true })
      },
      pane: (
        <Tab.Pane key='tenants' style={paneStyles}>
          <UserTenants user={id} />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'keys',
        content: 'API Keys',
        onClick: () => history(`/${currentLocation}/${id}/keys`, { replace: true })
      },
      pane: (
        <Tab.Pane key='keys' style={paneStyles}>
          <UserKeys user={id} />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'permissions',
        content: 'Permissions',
        onClick: () => history(`/${currentLocation}/${id}/permissions`, { replace: true })
      },
      pane: (
        <Tab.Pane key='permissions' style={paneStyles}>
          <UserPermissions permissions={permissions} />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        key: 'roles',
        content: 'Roles',
        onClick: () => history(`/${currentLocation}/${id}/roles`, { replace: true })
      },
      pane: (
        <Tab.Pane key='roles' style={paneStyles}>
          <UserRoles user={id} updateUser={updateUser} onRoleChange={onRoleChange} />
        </Tab.Pane>
      )
    }
  ]
  const defaultActiveIndex = getActiveTabIndex(panes, tab)
  const identifier = get(user, 'fullname', id)

  return (
    <div>
      <Breadcrumb titles={[NO_TITLE, identifier]} />
      <div style={{ height: '15px' }} />
      <Tab
        menu={{ vertical: true, fluid: true, tabular: true }}
        grid={{ tabWidth: 2, paneWidth: 14 }}
        defaultActiveIndex={defaultActiveIndex}
        panes={panes}
        renderActiveOnly={false}
      />
    </div>
  )
}

export default withRouter(Detail)

function getActiveTabIndex (tabs, active) {
  return tabs.findIndex(pane => pane.menuItem.key.toLowerCase() === active.toLowerCase())
}
