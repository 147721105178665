import React, { Component } from 'react'
import { Button, Form, Header, Icon, Segment, TextArea } from 'semantic-ui-react'
import { omit } from 'lodash'
import Breadcrumb, { NO_TITLE } from '../breadcrumb'

export default class ManageFilterForm extends Component {
  constructor (props) {
    super(props)
    const { id = '', name = '', filter = '' } = props.initialValues || {}
    this.state = { id, name, filter }
  }

  handleChange (property, event) {
    this.setState({ [property]: event.target.value })
  }

  submit (event) {
    event.preventDefault()
    this.props.onSubmit(omit(this.state, 'id'))
  }

  render () {
    const isEditing = !!this.props.initialValues
    return (
      <div>
        <Breadcrumb titles={[
          NO_TITLE,
          this.props.tenantName ?? this.props.tenantId,
          'Filters',
          isEditing ? this.state.name : undefined
        ]}
        />
        <Segment style={{ width: 460, margin: '0 auto', marginTop: 30 }}>
          <div id='top-bar'>
            <Header>{isEditing ? 'Edit Filter' : 'New Filter'}</Header>
            <Icon name={isEditing ? 'edit' : 'add'} />
          </div>
          <Form onSubmit={(e) => this.submit(e)}>
            <Form.Field>
              <label>Name</label>
              <input
                placeholder='name'
                value={this.state.name}
                readOnly={isEditing}
                disabled={isEditing}
                onChange={(event) => this.handleChange('name', event)}
              />
            </Form.Field>
            <Form.Field>
              <label>Definition</label>
              <TextArea
                placeholder='tag1;tag2'
                autoheight='true'
                value={this.state.filter}
                onChange={(event) => this.handleChange('filter', event)}
              />
            </Form.Field>
            <Button type='submit'>{isEditing ? 'Update' : 'Submit'}</Button>
          </Form>
        </Segment>
      </div>
    )
  }
}
